











































































































  import { PartnerProviderModel } from '@/store/partner/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { State } from 'vuex-class';

  @Component({
    name: 'detailFull',
    components: {
      partnerInfo: () => import('@/components/partner/info.vue'),
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class detailFull extends Vue {
    @State('providers', { namespace: 'partner' }) providers!: PartnerProviderModel[];

    assetPath = process.env.VUE_APP_ASSETS_URL;

    public get selectedPartner() {
      const id = String(this.$route.params.id) || 0;
      return this.providers.filter((v) => v.provider_id === id)[0];
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
